import { camelCase } from 'lodash';
import { ApolloError } from '@apollo/client';
import { ErrorOption } from 'react-hook-form';
import { TFunction } from 'i18next';

export const setFormError = (
  error: ApolloError,
  setError: (
    field: string,
    error: ErrorOption,
    options: { shouldFocus: true }
  ) => void,
  t: TFunction
) => {
  let errors: { [key: string]: string } = {};

  return error.message.split('\n').forEach((errorMessage: string) => {
    const errorMessageSlices = errorMessage.split(' ');
    const errorType = errorMessageSlices[0];
    const errorMesage = errors[errorType] ?? '';

    errors[errorType] = errorMesage
      .concat(' ')
      .concat(t(errorMessageSlices.splice(1).join(' ')));

    setError(
      camelCase(errorType),
      {
        type: 'manual',
        message: errors[errorType],
      },
      { shouldFocus: true }
    );
  });
};

export const FORM_ERRORS_MAP: Record<string, string> = {
  required: 'Campo obrigatório!',
};

const getPartsFromDate = (
  date: string,
  options: Intl.DateTimeFormatOptions | undefined
) => new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));

export const formatDateToSubmitDateString = (date: string) =>
  `${getPartsFromDate(date, { year: 'numeric' })}-${getPartsFromDate(date, {
    month: '2-digit',
  })}-${getPartsFromDate(date, { day: '2-digit' })}`;

export function handleValidateEntityType(entityType: string) {
  switch (entityType) {
    case 'INDIVIDUAL':
      return 'PF';
    case 'COMPANY':
      return 'PJ';
    default:
      break;
  }
}

const LOCALE_OPTIONS = 'pt-BR';

const INVALID_DATE_MESSAGE = 'Invalid Date';

const ONLY_STRINGS = (value: string) => typeof value === 'string';

const dateIsValid = (date: string) =>
  String(new Date(date)) !== INVALID_DATE_MESSAGE;

const includeDay = (value: string) =>
  new Date(
    Number(value.split('/')[1]),
    Number(value.split('/')[0]) - 1,
    1
  ).toLocaleDateString(LOCALE_OPTIONS);

export function unMaskFormFields(data: any) {
  const unMaskedData = { ...data };

  Object.keys(data)
    .filter((key) => ONLY_STRINGS(data[key]))
    .forEach((key) => {
      if (data[key]?.split('/')?.length >= 2 && data[key]?.split('.')?.length < 2) {
        return (unMaskedData[key] = formatDateToSubmitDateString(
          dateIsValid(data[key])
            ? new Date(data[key]).toLocaleDateString(LOCALE_OPTIONS)
            : new Date(includeDay(data[key])).toLocaleDateString(LOCALE_OPTIONS)
        ));
      } else if (data[key]?.split('R$')?.length >= 2) {
        return (unMaskedData[key] = data[key]
          .split('R$')[1]
          .replace('.', '')
          .replace(',', '.')
          .trim());
      } else if (data[key]?.split(',')?.length >= 2) {
        return (unMaskedData[key] = data[key].replace('.', '').replace(',', '.'));
      } else return (unMaskedData[key] = data[key]?.replace(/[^0-9a-zA-Z]/g, ''));
    });

  return unMaskedData;
}
