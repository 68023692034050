import classnames from 'classnames';
import React from 'react';

import BaseInput, { Props as BaseProps } from './BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    BaseProps & {
      label: string;
    },
  HTMLInputElement
>;

export const CheckboxInput = React.forwardRef<HTMLInputElement, Props>(
  ({ className, label, error, ...props }, ref) => {
    return (
      <label className="relative flex items-start">
        <span className="flex items-center h-5">
          <BaseInput
            ref={ref}
            error={error}
            type="checkbox"
            className={classnames(
              className,
              'h-5 w-5 focus:outline-none focus:ring-primary text-primary border-2 border-gray-dark500 bg-gray-dark600 rounded-md'
            )}
            {...props}
          />
        </span>
        <span className="ml-3 text-sm font-medium text-gray-dark500">{label}</span>
      </label>
    );
  }
);

export default CheckboxInput;
