import { useEffect, useState } from 'react';
import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import omit from 'lodash/omit';
import { FormContainer } from 'ui';
import SelectField from 'ui/form/SelectField';
import TextField from 'ui/form/TextField';
import NumberOnlyField from 'ui/form/NumberOnlyField';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';
import { CooperativeSelect } from 'cooperatives/models/cooperative';
import { FinancialAccountSelect } from 'cooperatives/models/cooperative';
import { Option } from 'ui/components/form/SelectInput';

type Field =
  | 'cooperativeHeadquarterId'
  | 'cooperativeSubscriptionCostCents'
  | 'cooperativeLegalName'
  | 'cooperativeDocumentId'
  | 'cooperativeAddressPostalCode'
  | 'cooperativeAddressState'
  | 'cooperativeAddressCity'
  | 'cooperativeAddressStreet'
  | 'cooperativeAddressComplement'
  | 'cooperativeAddressDistrict'
  | 'financialAccountId';

export const FORM_FIELDS: Field[] = [
  'cooperativeHeadquarterId',
  'cooperativeSubscriptionCostCents',
  'cooperativeLegalName',
  'cooperativeDocumentId',
  'cooperativeAddressPostalCode',
  'cooperativeAddressState',
  'cooperativeAddressCity',
  'cooperativeAddressStreet',
  'cooperativeAddressDistrict',
  'cooperativeAddressComplement',
  'financialAccountId',
];

export type FormFields = {
  cooperativeSubscriptionCostCents: number;
  cooperativeLegalName: string;
  cooperativeDocumentId: string;
  cooperativeAddressPostalCode: number;
  cooperativeAddressState: string;
  cooperativeAddressCity: string;
  cooperativeAddressStreet: string;
  cooperativeAddressDistrict: string;
  cooperativeAddressComplement: string | null;
  cooperativeHeadquarterId: number | null;
  financialAccountId: number | null;
};

export type FormErrors = {
  cooperativeSubscriptionCostCents?: FieldError | undefined;
  cooperativeLegalName?: FieldError | undefined;
  cooperativeDocumentId?: FieldError | undefined;
  cooperativeAddressPostalCode?: FieldError | undefined;
  cooperativeAddressState?: FieldError | undefined;
  cooperativeAddressCity?: FieldError | undefined;
  cooperativeAddressStreet?: FieldError | undefined;
  cooperativeAddressDistrict?: FieldError | undefined;
  cooperativeAddressComplement?: FieldError | undefined;
  cooperativeHeadquarterId?: FieldError | undefined;
  financialAccountId?: FieldError | undefined;
};

export default function CooperativeFormFields({
  headquarters,
  headquarterValue,
  financialAccounts,
  disableHeadquarterSelect,
  errors,
  register,
  control,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  control: Control<FormFields>;
  headquarters?: CooperativeSelect[];
  headquarterValue?: string;
  financialAccounts?: FinancialAccountSelect[];
  disableHeadquarterSelect?: boolean;
}) {
  const [headquarterOptions, setHeadquarterOptions] = useState<Option<string>[]>([]);
  useEffect(
    () =>
      setHeadquarterOptions([
        ...(headquarters
          ?.filter((cooperative) => !cooperative?.cooperativeHeadquarter)
          .map((cooperative) => ({
            key: `${cooperative.id}`,
            value: cooperative.cooperativeLegalName,
          })) || []),
      ]),
    [headquarters]
  );

  const [financialAccountOptions, setFinancialAccountOptions] = useState<
    Option<number>[]
  >([]);
  useEffect(
    () =>
      setFinancialAccountOptions(
        (financialAccounts instanceof Array &&
          financialAccounts.map((financialAccount) => ({
            key: financialAccount.id,
            value: financialAccount.financialAccountLegalName,
          }))) ||
          []
      ),
    [financialAccounts]
  );

  return (
    <FormContainer>
      <Controller
        name="cooperativeHeadquarterId"
        control={control}
        rules={{ required: false }}
        render={({ field }) => (
          <SelectField
            {...omit(field, 'ref', 'value')}
            error={errors.cooperativeHeadquarterId?.message}
            label="Nome da Matriz"
            id="cooperativeHeadquarterId"
            options={headquarterOptions}
            value={field.value ? `${field.value}` : undefined}
            disabled={disableHeadquarterSelect}
            required={false}
            emptyOptionLabel="Nenhuma"
          />
        )}
      />

      <TextField
        required
        error={errors.cooperativeLegalName?.message}
        label="Razão Social"
        id="cooperativeLegalName"
        {...register('cooperativeLegalName', { required: 'Campo obrigatório' })}
        placeholder="Ex.: Nex Energy Gestão de Energia"
      />
      <NumberOnlyField
        required
        error={errors.cooperativeSubscriptionCostCents?.message}
        label="Valor da subscrição (em centavos)"
        id="cooperativeSubscriptionCostCents"
        {...register('cooperativeSubscriptionCostCents', {
          required: 'Campo obrigatório',
          valueAsNumber: true,
          validate: (cost) => cost > 0,
        })}
        placeholder="2000"
      />
      <TextField
        required
        error={errors.cooperativeDocumentId?.message}
        label="Documento"
        id="cooperativeDocumentId"
        {...register('cooperativeDocumentId', { required: 'Campo obrigatório' })}
        placeholder="Digite o documento CPF/CNPJ"
      />
      <Controller
        name="financialAccountId"
        control={control}
        rules={{ required: 'Campo obrigatório' }}
        render={({ field }) => (
          <SelectField
            required
            {...omit(field, 'ref')}
            error={errors.financialAccountId?.message}
            label="Conta Financeira"
            shortCreation="/dashboard/billing-cycle/financial-accounts/create"
            emptyOptionLabel="Escolha uma Conta Financeira"
            id="financialAccountId"
            options={financialAccountOptions}
          />
        )}
      />
      <NumberOnlyField
        required
        error={errors.cooperativeAddressPostalCode?.message}
        label="CEP"
        id="cooperativeAddressPostalCode"
        {...register('cooperativeAddressPostalCode', {
          required: 'Campo obrigatório',
          valueAsNumber: true,
        })}
        placeholder="81000000"
        minLength={8}
        maxLength={8}
      />
      <Controller
        name="cooperativeAddressState"
        control={control}
        rules={{ required: 'Campo obrigatório' }}
        render={({ field: { ref: _ignore, ...field } }) => (
          <CountryStateSelectField
            required
            {...field}
            error={errors.cooperativeAddressState?.message}
            label="Estado"
            id="cooperativeAddressState"
          />
        )}
      />
      <TextField
        required
        error={errors.cooperativeAddressCity?.message}
        label="Cidade"
        id="cooperativeAddressCity"
        {...register('cooperativeAddressCity', { required: 'Campo obrigatório' })}
        placeholder="Ex.: Curitiba"
      />
      <TextField
        required
        error={errors.cooperativeAddressStreet?.message}
        label="Endereço"
        id="cooperativeAddressStreet"
        {...register('cooperativeAddressStreet', { required: 'Campo obrigatório' })}
        placeholder="Ex.: Comendador Franco 777"
      />
      <TextField
        error={errors.cooperativeAddressDistrict?.message}
        label="Bairro"
        id="cooperativeAddressDistrict"
        {...register('cooperativeAddressDistrict', { required: false })}
        placeholder="Ex.: Centro"
      />
      <TextField
        error={errors.cooperativeAddressComplement?.message}
        label="Complemento"
        id="cooperativeAddressComplement"
        {...register('cooperativeAddressComplement', { required: false })}
        placeholder="Ex.: Sala 1"
      />
    </FormContainer>
  );
}
